import React, { useEffect, useState } from "react";
import {
  DollarOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import Logo from "../../assets/logo.png";
import LogoCollapse from "../../assets/logo.svg";
import AppHeader from "../Header/Header";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import "./layout.css";
import { useStateGlobalContext } from "../../Context/GlobalContext";
import { getSession } from "../../customHooks";

const { Header, Sider, Content } = Layout;

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const { appLogoData, getAppLogo } = useStateGlobalContext();

  const menuItems = [
    {
      key: "/main",
      icon: <HomeOutlined />,
      label: "Dashboard",
      role: ["super admin", "super admin manager"],
    },
    {
      key: "/main/clients",
      icon: <UsergroupAddOutlined />,
      label: "Clients",
      role: ["super admin", "super admin manager"],
    },
    {
      key: "/main/managers",
      icon: <UserOutlined />,
      label: "Managers",
      role: ["super admin"],
    },
    {
      key: "/main/admin-settings",
      icon: <UserOutlined />,
      label: "Admin Settings",
      role: ["super admin", "super admin manager"],
    },
    {
      key: "/main/register-clinic",
      icon: <UserOutlined />,
      label: "Register Clinic",
      role: ["super admin", "super admin manager"],
    },
    {
      key: "/main/super-audit-log",
      icon: <UserOutlined />,
      label: "Audit Log",
      role: ["super admin", "super admin manager"],
    },
  ];

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    getAppLogo();
  }, []);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const filteredMenuItems = menuItems.filter(item => item.role.includes(getSession("role")));

  return (
    <Layout className="h-screen">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          background: "#f7f7f7",
          height: "100vh",
          position: "fixed",
          borderRight: "1px solid #f0f0f0",
          transition: "width 0.3s",
          zIndex: 1,
        }}
        className="lg:fixed lg:left-0 lg:top-0 lg:h-screen"
        width={256}
      >
        <div
          className="flex justify-center brand"
          style={{
            transition: "margin-left 0.2s",
          }}
        >
          {collapsed ? (
            <img src={LogoCollapse} alt="Logo" className="w-[2rem] my-5 logo" />
          ) : (
            <img
              src={appLogoData?.icon || Logo}
              alt="Logo"
              className="w-[10rem] my-5"
            />
          )}
        </div>
        <Menu
          style={{
            background: "#f7f7f7",
            marginTop: "1rem",
          }}
          mode="inline"
          className="mt-2"
          defaultSelectedKeys={["1"]}
          selectedKeys={[location.pathname]}
          onClick={({ key }) => navigate(key)}
        >
          {filteredMenuItems.map((item) => (
            <Menu.Item key={item.key} icon={item.icon}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: "#fff",
            width: collapsed ? "calc(100% - 80px)" : "calc(100% - 256px)",
            position: "fixed",
            minWidth: collapsed ? "calc(100% - 80px)" : "calc(100% - 256px)",
            marginLeft: collapsed ? "80px" : "256px",
            zIndex: 29,
            transition: "margin-left 0.2s",
          }}
          className="header-class lg:ml-80px lg:fixed lg:top-0 lg:w-[calc(100%-80px)] shadow-sm"
        >
          <AppHeader collapsed={collapsed} toggleSidebar={toggleSidebar} />
        </Header>
        <Content
          style={{
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            marginLeft: collapsed ? "80px" : "256px",
            transition: "margin-left 0.2s",
          }}
          className="lg:ml-80px mt-[80px]"
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default App;
