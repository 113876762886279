import React from "react";
import { Modal, Button, Row, Col, Form, Input, message } from "antd";
import axios from "axios";
import {
  validateName,
  validatePassword,
  validateUsername,
} from "../../../utils/utils";
import { getSession } from "../../../customHooks";
import { doPost } from "../../../components/API/apis";



const AddManagers = ({ open, onClose }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const data = {
      username: values.username,
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      password: values.password,
      confirm_password: values.confirm_password,
    };
    try {
      const response = await doPost(
        `/administration/super-admin-manager-register/`,
        data,
      );
      if (response.status === 201) {
        message.success("Manager Added Successfully");
        handleCancel()
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = ()=>{
    onClose()
    form.resetFields()
  }

  return (
    <Modal title="Add Staff" open={open} footer={null} onCancel={onClose}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                { required: true, message: "Please input your first name!" },
                { validator: validateName },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                { required: true, message: "Please input your last name!" },
                { validator: validateName },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="username"
          label="Username"
          rules={[
            { required: true, message: "Please enter the username" },
            { validator: validateUsername },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please enter the email" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please enter the password" },
                { validator: validatePassword },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              hasFeedback
              dependencies={["password"]}
              rules={[
                { required: true, message: "Please confirm the password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-end">
          <Button className="mr-2" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Add Staff
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddManagers;
