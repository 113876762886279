import React, { useState, useEffect } from "react";
import { Table, Card } from "antd";
import { useStateGlobalContext } from "../../../Context/GlobalContext";
import { formatDate } from "../../../utils/utils";

const coloumn = [
  {
    key: "#",
    title: "#",
    align: "center",
    render: (_, __, index) => index + 1,
  },
  {
    key: 1,
    title: "Username",
    dataIndex: "user__username",
  },
  {
    key:7,
    title:"Last Login",
    dataIndex:"user__last_login",
    render:(text)=> text && formatDate(text)
  },
  {
    key: 2,
    title: "Full Name",
    render: (_, record) =>
      `${record?.user__first_name} ${record?.user__last_name}`,
  },
  {
    key: 3,
    title: "Office Name",
    dataIndex: "office_settings_Basic__facility_name",
    render: (facilityName) => (facilityName ? facilityName[0] : ""),
  },
  {
    key: 4,
    title: "Country",
    dataIndex: "office_settings_Basic__country",
  },
  {
    key: 5,
    title: "Email",
    dataIndex: "user__email",
  },
  {
    key: 6,
    title: "Signup Date",
    dataIndex: "user__registration_date",
  },
];

const RecentlyAddedUsers = () => {
  const [loading, setLoading] = useState([]);
  const { clients, getClientDetails } = useStateGlobalContext();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await getClientDetails();
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <Card>
      <h1 className="font-bold text-xl">Recently Added New Users </h1>
      <Table
        loading={loading}
        columns={coloumn}
        dataSource={clients}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          total: clients?.length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
        }}
        size="small"
        className="mt-4"
      />
    </Card>
  );
};

export default RecentlyAddedUsers;
