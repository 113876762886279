import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { getSession , clearSession} from '../customHooks/useSession';
import { useIdle } from "@uidotdev/usehooks";

function Protected(props) {
  const {Component} = props;
  const navigate = useNavigate()
  const idle = useIdle(900000);

  useEffect(()=>{
    const token = getSession("admintoken");
    if(!token){
      navigate("/")
  }
  })

  useEffect(() => {
    if (idle) {
      clearSession()
    } 
  }, [idle]);

  return getSession("admintoken") && (
    <div>
        <Component/>
    </div>
  )
}

export default Protected