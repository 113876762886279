import React, { useEffect, useState } from "react";
import { MenuOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Dropdown, Avatar } from "antd";
import { Link } from "react-router-dom";
import { clearSession } from "../../customHooks";
import { useStateGlobalContext } from "../../Context/GlobalContext";

const AppHeader = ({ collapsed, toggleSidebar }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const { superAdminDetail, getSuperAdminDetails } = useStateGlobalContext();

  const fullName = superAdminDetail && `${superAdminDetail[0]?.first_name} ${superAdminDetail[0]?.last_name}`

  useEffect(()=>{
    getSuperAdminDetails()
  },[])
  const items = [
    {
      key: "1",
      icon: <UserOutlined />,
      label: (
        <Link to="/main/profile">
          <span>Profile</span>
        </Link>
      ),
    },
    {
      key: "3",
      icon: <LogoutOutlined onClick={() => clearSession()} />,
      label: (
        <span
          onClick={() => {
            clearSession();
          }}
        >
          Logout
        </span>
      ),
    },
  ];

  return (
    <div className="flex justify-between items-center px-2">
      <div className="ml-4">
        {collapsed && (
          <div className="cursor-pointer" onClick={toggleSidebar}>
            <MenuOutlined />
          </div>
        )}
        {!collapsed && (
          <div className="cursor-pointer" onClick={toggleSidebar}>
            <MenuOutlined />
          </div>
        )}
      </div>

      <div>
        <Dropdown
          menu={{ items }}
          open={isDropdownVisible}
          onOpenChange={setDropdownVisible}
        >
          <div className="flex items-center px-3 py-2 rounded-full cursor-pointer">
            <div className="mr-2">
              <p className="text-sm font-semibold">{fullName || "Saad Mehfooz"} </p>
              <p className="text-xs">Super Admin</p>
            </div>
            <Avatar shape="circle">{fullName[0] || "S"}</Avatar>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default AppHeader;
