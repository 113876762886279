import React, { useEffect } from "react";
import { Card, Row, Col } from "antd";
import { UserOutlined , DollarCircleOutlined} from "@ant-design/icons";
import { useStateGlobalContext } from "../../../Context/GlobalContext";


const DashboardCard = ({loading , title, totalNumber, icon}) => {
  return (
    <Card hoverable className="shadow-sm" loading={loading}>
      <div className="flex justify-between items-center">
        <div className="">
          <p className="font-medium text-sm mb-2">{title}</p>
          <h1 className="font-bold text-xl leading-4">{totalNumber}</h1>
        </div>

        <div>{icon}</div>
      </div>
    </Card>
  );
};

const DashboardCards = () => {
  const {loading, clinicCount, getClinicCount} = useStateGlobalContext()

  useEffect(()=>{
    getClinicCount()
  },[])

  return (
    <Row gutter={[16,16]} className="mt-4">
      <Col xs={24} md={12} lg={12}>
        <DashboardCard
          loading={loading}
          title="Total Active Clients"
          totalNumber={clinicCount && `${clinicCount?.active_clinic_count} of ${clinicCount?.total_clinics}`}
          icon={<UserOutlined style={{ fontSize: '24px', color: '#08c' }}/>}
        />
      </Col>
      <Col xs={24} md={12} lg={12}>
        <DashboardCard
        loading={loading}
          title="Pending Payments"
          totalNumber={clinicCount && clinicCount?.pending_payment || 0}
          icon={<DollarCircleOutlined style={{ fontSize: '24px', color: '#08c' }}/>}
        />
      </Col>
    </Row>
  );
};

export default DashboardCards;
