import { Button, Card, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { doGet } from "../../../components/API/apis";

const getMaskedCardNumber = (cardNumber) => {
  const maskedDigits = cardNumber?.slice(0, -4).replace(/\d/g, "*");
  const visibleDigits = cardNumber?.slice(-4);
  const formattedNumber = maskedDigits?.replace(/(.{4})/g, "$1 ");
  return formattedNumber + visibleDigits;
};

const getUnMaskSpace =(cardNumber)=>{
  const formattedNumber = cardNumber?.replace(/(.{4})/g, "$1 ");
  return formattedNumber 
}



const ClinicPaymentMethod = ({ open, onClose, record, title }) => {
  const [loading, setLoading] = useState(false);
  const [clinicPaymentInfo, setClinicPaymentInfo] = useState(null);
  const [showCreditCardNumber, setShowCreditCardNumber] = useState(false)

  const showCreditCardInfo = ()=>{
    setShowCreditCardNumber(!showCreditCardNumber)
  }


  const getClinicPaymentDetails = async (record) => {
    setLoading(true);
    try {
      const response = await doGet(
        `/administration/account_owner_payment_info/get/${record?.id}/`
      );
      if (response.status === 200) {
        setClinicPaymentInfo({
          credit_card: response?.data?.credit_card_info[0],
          invoice_email: response?.data?.email_info_serializer[0],
        });
      }
    } catch (error) {
      message.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    open && getClinicPaymentDetails(record);
  }, [record]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={`Payment Preference - ${title}`}
      footer={null}
    >
      <Card className="mb-2">
        <div className="p-2">
          {loading && <p>Loading...</p>}
          {clinicPaymentInfo && (
            <>
              <p className="text-md font-semibold mb-4">Credit Card Info</p>
              <div className="mb-2">
                <p className="text-sm mb-1">Card Holder Name:</p>
                <p className="text-md font-medium">
                  {clinicPaymentInfo?.credit_card?.card_holder_name}
                </p>
              </div>
              <div className="mb-2">
                <p className="text-sm mb-1">Credit Card Number:</p>
                <div className="flex items-center">
                <p className="text-md font-medium">
                  {!showCreditCardNumber && getMaskedCardNumber(clinicPaymentInfo?.credit_card?.credit_card_number)}
                  {showCreditCardNumber && getUnMaskSpace(clinicPaymentInfo?.credit_card?.credit_card_number)}
                </p>
                <Button type="primary" className="ml-4" size="small" onClick={(showCreditCardInfo)}>{showCreditCardNumber ? 'Hide': "Show"}</Button>
                </div>
              </div>
              <div className="mb-2 flex justify-between">
                <div className="w-1/2 mr-2">
                  <p className="text-sm mb-1">CVV:</p>
                  <p className="text-md font-medium">
                    {clinicPaymentInfo?.credit_card?.cvv_security_code}
                  </p>
                </div>
                <div className="w-1/2 ml-2">
                  <p className="text-sm mb-1">Expiration:</p>
                  <p className="text-md font-medium">
                    {clinicPaymentInfo?.credit_card?.expiration_date}
                  </p>
                </div>
              </div>
              <div className="mb-2">
                <p className="text-sm mb-1">Zip Code:</p>
                <p className="text-md font-medium">
                  {clinicPaymentInfo?.credit_card?.zipcode}
                </p>
              </div>
              <div className="mb-2">
                <p className="text-sm mb-1">Billing Address:</p>
                <p className="text-md font-medium">
                  {clinicPaymentInfo?.credit_card?.billing_address}
                </p>
              </div>
            </>
          )}
          {clinicPaymentInfo?.credit_card === null && !loading && (
            <p>No payment information available</p>
          )}
        </div>
      </Card>

      <Card>
      <div className="mb-2">
        <p className="text-md font-semibold mb-1">Client Email for Invoice</p>
        <p className="text-md font-medium">
          {clinicPaymentInfo?.invoice_email?.email ? clinicPaymentInfo?.invoice_email?.email : "No Email Added"}
        </p>
      </div>
      </Card>
    </Modal>
  );
};

export default ClinicPaymentMethod;
