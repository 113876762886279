import { DateTime } from "luxon";

export const formatDate = (date, format="en-US", officeTimezone)=>{
    return DateTime.fromISO(date, { zone: 'UTC' }).setZone(officeTimezone).setLocale(format).toLocaleString()
  }

  export const validateName = (_, value) => {
    if (value && !/[a-zA-Z\s]+$/.test(value)) {
      return Promise.reject(
        "Name must only contain alphabetic characters and spaces"
      );
    } else {
      return Promise.resolve();
    }
  };
  
  export const emailValidator = (_, value, callback) => {
    if (
      value &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(value)
    ) {
      return Promise.reject("Invalid email address");
    } else {
      return Promise.resolve();
    }
  };
  
  export const validateUsername = (_, value) => {
    if (value && !/^[a-zA-Z0-9_]{8,20}$/.test(value)) {
      return Promise.reject(
        "Username must be between 8 and 20 characters and contain only alphanumeric characters and underscores"
      );
    }
    return Promise.resolve();
  };

  export const validatePassword = (_, value) => {
    if (value) {
      if (
        !/(?=.*[A-Z])/.test(value) ||
        !/(?=.*\d)/.test(value) ||
        !/(?=.*[!@#$%^&*])/.test(value)
      ) {
        return Promise.reject(
          "Password must contain at least one uppercase letter, one number, and one special character (!@#$%^&*)"
        );
      }
    }
    return Promise.resolve();
  };