import React, { createContext, useContext, useState } from "react";
import { doGet } from "../components/API/apis";
import { message } from "antd";

const GlobalContext = createContext();
export const GlobalContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(false)
    const [clinicCount, setClinicCount] = useState([])
    const [clients, setClients] = useState([])
    const [managers, setManagers] = useState([])
    const [superAdminDetail, setSuperAdminDetail] = useState([])
    const [bankInformation , setBankInformation]  = useState([])
    const [appLogoData, setAppLogoData] = useState([])
    const [clinicPaymentStatus , setClinicPaymentStatus] = useState([])


  const handleApiError = (error) => {
    message.error("Something went wrong");
    console.error(error);
  };
  // office/count/
  const getClinicCount = async ()=>{
    setLoading(true)
    try{
      const response = await doGet('/administration/office/count/')
      if(response.status === 200){
        setClinicCount(response.data)
      }
    }catch(error){
      handleApiError(error)
    } finally{
      setLoading(false)
    }
  }

  const getClientDetails = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/administration/dashboard/`);
      if (response.status === 200) {
        const sortedClients = response?.data?.sort((a, b) => {
          const dateA = new Date(a?.user__registration_date);
          const dateB = new Date(b?.user__registration_date);
          return dateB - dateA;
        });
        setClients(sortedClients);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const getAppLogo = async () => {
    setLoading(true);
    try {
      const response = await doGet("/administration/retrieve-application-images/");
      if (response.status === 200) {
        setAppLogoData(response.data.data);
      } else if (response.status === 400){
        setAppLogoData(null)
      }
    } catch (error) {
      handleApiError()
    } finally {
      setLoading(false);
    }
  };

  const getBankInformation = async ()=>{
    setLoading(true)
    try{
      const response = await doGet(`/administration/retrieve-super-admin-note/1/`)
      if(response.status === 200){
        setBankInformation(response?.data?.data)
      }
    }
    catch(error){
      handleApiError(error)
    } finally{
      setLoading(false)
    }
  }


  const getManagers = async ()=>{
    setLoading(true);
    try {
      const response = await doGet(`/administration/retrieve/superadmin-managers/`);
      if (response.status === 200) {
        setManagers(response.data.reverse())
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  }

  const getPaymentStatus = async (id) => {
    setLoading(true)
    try {
      const response = await doGet(`/user/list/status/${id}/`);
      if (response.status === 200) {
          setClinicPaymentStatus(response.data)
      } else if(response.status === 404){
        setClinicPaymentStatus([])
      }
    } catch (error) {
      handleApiError(error)
    } finally{
      setLoading(false)
    }
  };

  const getSuperAdminDetails = async ()=>{
    setLoading(true)
    try{
      const response = await doGet('/administration/super-admin-detail/')
      if(response.status === 200){
        setSuperAdminDetail(response.data)
      }
    }
    catch(error){
      handleApiError(error)
    }
    finally{
      setLoading(false)
    }
  }

 

  return (
    <GlobalContext.Provider
      value={{
        loading,
        clients,
        clinicCount,
        getClinicCount,
        managers,
        getManagers,
        getClientDetails,
        appLogoData,
        getAppLogo,
        bankInformation,
        getBankInformation,
        clinicPaymentStatus,
        setClinicPaymentStatus,
        getPaymentStatus,
        superAdminDetail,
        getSuperAdminDetails
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
export const useStateGlobalContext = () => useContext(GlobalContext);
