import { Button, Card } from "antd";
import React, { useState } from "react";
import RecentlyAddedUsers from "../Dashboard/components/RecentlyAddedUsers";
import RegisterClinicModal from "../../components/RegisterClinicModal/RegisterClinicModal";


const RegisterClinic = () => {
  const [registerClinic, setRegisterClinic] = useState(false)


  const onClose = ()=>{
    setRegisterClinic(false)
  }

  return (
    <Card>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-lg">American EMR Clients</h1>
        <Button type="primary" onClick={()=> setRegisterClinic(true)}>Register New Clinic</Button>
      </div>
      <RecentlyAddedUsers />

      <RegisterClinicModal open={registerClinic} onClose={onClose}/>
    </Card>
  );
};

export default RegisterClinic;
