import React, { useEffect } from "react";
import { Modal, Button, Form, Input, DatePicker,  Select, message } from "antd";
import dayjs from 'dayjs'
import {doPatch} from '../../../components/API/apis'
import { useStateGlobalContext } from "../../../Context/GlobalContext";

const {Option} = Select

const ChangeStatus = ({ open, onClose, record }) => {
  const [form] = Form.useForm()
  const { getClientDetails, clinicPaymentStatus , getPaymentStatus} = useStateGlobalContext()

  const onCancel = ()=>{
    onClose()
    form.resetFields()
  }

  const udpateStatus = async (values)=>{
    const data = {
      status: values?.status || record?.showstatus__status,
      notes: values?.notes ? values?.notes : "" ,
      extension: values?.extension && dayjs(values?.extension)?.format('YYYY-MM-DD'),
      date_time: values?.paid_date ? values?.paid_date : null
    }
    try{
      const response = await doPatch(`/administration/update/${record?.id}/`, data)
      if(response.status === 200){
        message.success("Status Updated Successfully")
        onCancel()
        getClientDetails()
      }
    }
    catch(error){
      message.error(error)
    }
  }

  useEffect(() => {
    if (clinicPaymentStatus) {
      form.setFieldsValue({
        status: clinicPaymentStatus?.status,
        notes: clinicPaymentStatus?.notes,
        paid_date: clinicPaymentStatus?.date_time && dayjs(clinicPaymentStatus?.date_time),
        extension: clinicPaymentStatus?.extension && dayjs(clinicPaymentStatus?.extension)
      });
    }
  }, [clinicPaymentStatus]);

  useEffect(() => {
    if (open && record?.id) { // Add record?.id to ensure it's not undefined
      getPaymentStatus(record?.id);
    }
  }, [open, record?.id]);



  return (
    <Modal open={open} onCancel={onClose} footer={null} title="Update Status">
 <Form layout="vertical" form={form} onFinish={udpateStatus}>
        <Form.Item label="Payment Status" name="status">
          <Select
            defaultValue={
              record?.showstatus__status === null ? "unpaid" : "paid"
            }
          >
            <Option value="unpaid">Unpaid</Option>
            <Option value="paid">Paid</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Payment Notes for Client" name={"notes"}>
          <Input.TextArea
            rows={4}
            maxLength={500}
            showCount
            placeholder="Notes..."
          />
        </Form.Item>
        <div className="flex">
        <Form.Item label="Date Paid" name={"paid_date"}>
          <DatePicker format={"MM-DD-YYYY"} />
        </Form.Item>
        <Form.Item label="Extension Unitl" name={"extension"} className="ml-2">
          <DatePicker format={"MM-DD-YYYY"} />
        </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" htmlType="submit" className="ml-2">
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangeStatus;
