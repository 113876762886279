import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal, message } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { doGet, doPatch, doPost } from "../../../components/API/apis";
import { useStateGlobalContext } from "../../../Context/GlobalContext";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    ["bold", "italic", "underline", "strike"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }],
    ["link", "image"],
    ["clean"],
  ],
};

const AddBankDetails = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false)
  const { appLogoData, getAppLogo, bankInformation, getBankInformation } =
    useStateGlobalContext();

    const showNote = () => {
      setVisible(true);
  
      setTimeout(() => {
        form.setFieldsValue({
          super_admin_payment_bank_info: bankInformation?.super_admin_payment_bank_info,
        });
      }, 0); 
    };
  

  const hideNote = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleView = ()=>{
    setView(true)
  }

  const handleBankInformation = async (values) => {
    try {
      const url =
        appLogoData !== null &&
        bankInformation?.super_admin_payment_bank_info?.length >= 0
          ? "/administration/super-admin-note/update/"
          : "/administration/super-admin-note/";

      const response =
        appLogoData !== null &&
        bankInformation?.super_admin_payment_bank_info?.length >= 0
          ? await doPatch(url, values)
          : await doPost(url, values);
      if (response.status === 201) {
        message.success("Bank Information Added Successfully");
        getBankInformation();
        hideNote();
      } else if (response.status === 200) {
        message.success("Bank Information Updated Successfully");
        getBankInformation();
        hideNote();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBankInformation();
    getAppLogo();
  }, []);
  return (
    <>
      <Card className="mb-2">
        <div className="flex justify-between">
          <p className="font-bold text-md">Bank Transfer Details</p>
          <Button type="primary" onClick={showNote}>
            Add Bank Details
          </Button>
        </div>

        <Button type="primary" onClick={handleView}>View Bank Information </Button>
      </Card>

      <Modal
        title="Bank Details"
        footer={null}
        open={visible}
        width={700}
        onCancel={hideNote}
      >
        <Form layout="vertical" form={form} onFinish={handleBankInformation}>
          <Form.Item
            label="Add Bank Details"
            name="super_admin_payment_bank_info"
          >
            <ReactQuill
              theme="snow"
              modules={modules}
              style={{ height: "500px" }}
            />
          </Form.Item>
          <div className="mt-14 flex justify-end">
            <Button onClick={hideNote}>Cancel</Button>
            <Button type="primary" className="ml-2" htmlType="submit">
              {bankInformation?.super_admin_payment_bank_info?.length >= 0
                ? "Update"
                : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        open={view}
        onCancel={()=> setView(false)}
        footer={null}
       title="Bank Information"
       width={900}
      >
          {bankInformation?.super_admin_payment_bank_info  ? <div dangerouslySetInnerHTML={{ __html: bankInformation?.super_admin_payment_bank_info }} /> :
           <p>No Information Added yet</p>
          }
      </Modal>
    </>
  );
};

export default AddBankDetails;
