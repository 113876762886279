import React, { useEffect, useState } from "react";
import {
  Table,
  Modal,
  message,
  Dropdown,
  Switch,
  Tag,
  Card,
  Button,
} from "antd";
import {  EllipsisOutlined } from "@ant-design/icons";
import { formatDate } from "../../utils/utils";
import { useStateGlobalContext } from "../../Context/GlobalContext";
import { doDelete, doPatch } from "../../components/API/apis";
import ChangeStatus from "./component/ChangeStatus";
import ViewClientRecord from "./component/ViewClientRecord";
import ClinicPaymentMethod from "./component/ClinicPaymentMethod";
import { CSVLink } from "react-csv";
import { GetColumnSearchProps } from "../../components/TableSearch/TableSearch";
import { getSession } from "../../customHooks";

const paymentMethod = (method)=>{
  if(method === 'bank_transfer'){
    return "Bank Transfer"
  } else if(method === 'credit_card'){
    return "Credit Card"
  } else if(method === 'monthly_invoice'){
    return "Monthly Invoice"
  } else{
    return "No Payment Method Selected"
  }
}

const action_buttons = [
  { key: 1, label: "View Stats" },
  { key: 2, label: "Payment Preference" },
  { key: 3, label: "Update Status" },
];

const delete_button = [
  {key:1, label: "Delete"}
]

const transformedClientList = (record) => {
  if (!record || record.length === 0) {
    return [];
  }

  return record.map((item, index) => ({
    key: index + 1,
    "Facility Name": item?.office_settings_Basic__facility_name ? item?.office_settings_Basic__facility_name[0] : "",
    "Display Name": item?.office_settings_Basic__facility_name ? item?.office_settings_Basic__facility_name[1] : "",
    "Clinic ID": item?.user__id || "",
    "First Name": item?.user__first_name || "",
    "Last Name": item?.user__last_name || "",
    "Username": item?.user__username || "",
    "Signup Date": formatDate(item?.user__registration_date) || "",
    "Email": item?.user__email || "",
    "Phone Number": item?.office_settings_Basic__office_phone ? item?.office_settings_Basic__office_phone[0] : "",
    "Work": "", 
    "Speciality Type": item?.office_settings_Basic__speciality_type || "",
    "Website Address": item?.office_settings_Basic__website || "",
    "Physical Address": item?.office_settings_Basic__facility_address ? item?.office_settings_Basic__facility_address[0] : "",
    "City": item?.office_settings_Basic__city ? item?.office_settings_Basic__city[0] : "",
    "Country": item?.office_settings_Basic__country ? item?.office_settings_Basic__country[0] : "",
    "Zip Code": item?.office_settings_Basic__zipcode ? item?.office_settings_Basic__zipcode[0] : "",
    "Payment Method": paymentMethod(item?.showstatus__payment_method) || "",
    "Payment Status": item?.showstatus__status === "paid" ? "Paid" : item?.showstatus__status === "unpaid" ? "Unpaid" : "",
    "Date Paid": item?.showstatus__date_time ? formatDate(item?.showstatus__date_time) : "",
    "Extension Months": item?.showstatus__extension ? formatDate(item?.showstatus__extension) : "",
    "Active / Inactive": item?.user__is_active ? "Active" : "Inactive",
    "T Users" : "",
    "T Active User": "",
    "T Registered Patient": "",
    "Date Usage": "",
    "Payment Due": ""
  }));
};


const Clients = () => {
  const [loading, setLoading] = useState(false);
  const { clients, getClientDetails } = useStateGlobalContext();
  const [showEditable, setShowEditable] = useState(false);
  const [editableRecord, setEditableRecord] = useState([]);
  const [viewRecord, setViewRecord] = useState(null);
  const [showPaymenentModal, setShowPaymentModal] = useState(null);

  const handleStatusChange = (record, checked) => {
    Modal.confirm({
      title: "Confirm Change Status?",
      icon: null,
      content: `Are you sure you want to make this Client ${
        checked == true ? "Active" : "Inactive"
      }?`,
      okText: `Mark as ${checked == true ? "Active" : "Inactive"}`,
      okType: "warning",
      cancelText: "Cancel",
      className: "custom-warning-modal",
      onOk: () => {
        handleChangeClinicStatus(record, checked);
      },
    });
  };
  const handleChangeClinicStatus = async (record, checked) => {
    const data = {
      clinic_id: record?.user__id,
      status: checked, 
    };

    try {
      const response = await doPatch(
        `/administration/set-clinic/inactive/`,
        data
      );
      if (response.status === 200) {
        message.success("Status Changed Successfully");
        await getClientDetails();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const coloumn = [
    {
      key: "#",
      title: "#",
      fixed: "left",
      render: (_, __, index) => index + 1,
    },
    {
      key: 9,
      title: "Facility Name",
      fixed: "left",
      dataIndex: "office_settings_Basic__facility_name",
      render: (facilityName) => (facilityName ? facilityName[0] : ""),
    },
    {
      key: "display_name",
      title: "Display Name",
      dataIndex: "office_settings_Basic__facility_name",
      render: (text) => (text ? text[1] : ""),
    },
    {
      key: 1,
      title: "Clinic ID",
      align: "center",
      dataIndex: "user__id",
    },
    {
      key: 2,
      title: "First Name",
      dataIndex: "user__first_name",
    },
    {
      key: 3,
      title: "Last Name",
      dataIndex: "user__last_name",
    },
    {
      key: 4,
      title: "Username",
      dataIndex: "user__username",
      ...GetColumnSearchProps("user__username")
    },
    {
      key: 5,
      title: "Signup Date",
      dataIndex: "user__registration_date",
      render: (text) => text && formatDate(text)
    },
    {
      key: 20,
      title:"Last Login",
      dataIndex:"user__last_login",
      render:(text)=> text && formatDate(text)
    },
    {
      key: 6,
      title: "Email",
      dataIndex: "user__email",
    },
    {
      key: 7,
      title: "Phone Number",
      dataIndex: "office_settings_Basic__office_phone",
      render: (text) => (text ? text[0] : ""),
    },
    {
      key: 8,
      title: "Fax",
      align:'center',
      dataIndex: "office_settings_Basic__office_fax",
      render: (text) => (text ? text[0] : ""),
    },

    {
      key: 10,
      title: "Speciality Type",
      dataIndex: "office_settings_Basic__speciality_type",
      render: (text) => (text ? text[0] : ""),
    },
    {
      key: 11,
      title: "Website Address",
      dataIndex: "office_settings_Basic__website",
    },
    {
      key: "physical_address",
      title: "Physical Address",
      dataIndex: "office_settings_Basic__facility_address",
      render: (text) => (text ? text[0] : ""),
    },
    {
      key: 13,
      title: "City",
      dataIndex: "office_settings_Basic__city",
      render: (text) => (text ? text[0] : ""),
    },
    {
      key: 12,
      title: "Country",
      dataIndex: "office_settings_Basic__country",
      render: (text) => (text ? text[0] : ""),
    },
    {
      key: "zip_Code",
      title: "Zip Code",
      align: "center",
      dataIndex: "office_settings_Basic__zipcode",
      render: (text) => (text ? text[0] : " "),
    },

    {
      key: "payment_method",
      title: "Payment Method",
      dataIndex: "showstatus__payment_method",
      render: (text) => paymentMethod(text),
      align:'center'
    },
    {
      key: 16,
      title: "Payment Status",
      dataIndex: "showstatus__status",
      align: "center",
      render: (text) =>
        text === "paid" ? (
          <Tag color="#18900f">Paid</Tag>
        ) : text === "unpaid" ? (
          <Tag color="#9ca3af">Unpaid</Tag>
        ) : (
          <span></span>
        ),
    },
    {
      key: 17,
      title: "Date Paid",
      dataIndex: "showstatus__date_time",
      render: (text) => text && formatDate(text),
    },
    {
      key: 19,
      title: "Extension Until",
      dataIndex: "showstatus__extension",
      render: (text) => {
        let backgroundColorClass = "";
        if (text !== null) {
          const expiryDate = text !== null ? new Date(text) : "";
          const today = new Date();
          const nextMonth = new Date();
          nextMonth.setDate(today.getDate() + 30);

          if (expiryDate < today) {
            backgroundColorClass = "bg-red-300";
          } else if (expiryDate < nextMonth) {
            backgroundColorClass = "bg-yellow-200";
          } else if (expiryDate === null) {
            backgroundColorClass = "bg-transparent";
          } else {
            backgroundColorClass = "bg-transparent";
          }
        }
        return (
          <span className={`px-3 py-[4px] rounded ${backgroundColorClass}`}>
            {text && formatDate(text)}
          </span>
        );
      },
    },
    {
      key: 20,
      title: "Active / Inactive",
      align: "center",
      render: (text, record) => (
        <Switch
          // size="small"
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          checked={record?.user__is_active}
          onChange={(checked) => handleStatusChange(record, checked)}
        />
      ),
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      onFilter: (value, record) => record?.user__is_active === value,
    },
      getSession("role") === 'super admin' && {
        key: 21,
        title: "Danger",
        align: "center",
        render: (_, record) => {
          return (
            <Dropdown
              menu={{
                items: delete_button?.map((item) => ({
                  ...item,
                  onClick: () => {
                    if (item.key === 1) {
                      handleDeleteClient(record?.user__username);
                    }
                  },
                })),
              }}
              trigger={["click"]}
            >
              <EllipsisOutlined className="cursor-pointer" />
            </Dropdown>
          );
        },
    },
    {
      key: 22,
      title: "Action",
      align: "center",
      fixed: "right",
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: action_buttons?.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 1) {
                    handleView(record);
                  } else if (item.key === 2) {
                    handlePaymentModal(record);
                  } else if (item.key === 3) {
                    handleEdit(record);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ].filter(Boolean);

  const handleView = (record) => {
    setViewRecord({
      show: true,
      record: record,
    });
  };

  const closeViewRecord = () => {
    setViewRecord(null);
  };

  const handleEdit = (record) => {
    setShowEditable(true);
    setEditableRecord(record);
  };

  const closeEditModal = () => {
    setShowEditable(false);
    setEditableRecord(null);
  };

  const handlePaymentModal = (record) => {
    console.log(record, "HELLO ")
    setShowPaymentModal({
      show: true,
      payment_method_record: record,
    });
  };

  const closePaymentModal = () => {
    setShowPaymentModal(null);
  };

  const handleDeleteClient = (record) => {
    Modal.confirm({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk: () => {
        deleteClient(record);
      },
    });
  };

  const udpateStatus = async ()=>{
    Modal.confirm({
      title: "Confirm Action?",
      icon: null,
      content: "Are you sure you want to make all Mark as Unpaid",
      okText: `Mark All as Unpaid`,
      okType: "warning",
      cancelText: "Cancel",
      className: "custom-warning-modal",
      onOk: () => {
        markAllUnpaid();
      },
    });
  }  

  const markAllUnpaid = async ()=>{
    const data = {
      status: "unpaid",
    }
    try{
      const response = await doPatch(`/administration/update/`, data)
      if(response.status === 200){
        message.success("Mark All as Unpaid Successfully")
        getClientDetails()
      }
    }
    catch(error){
      message.error(error)
    }
  }

  const deleteClient = async (user_name) => {
    try {
      const response = await doDelete(`/administration/delete/${user_name}/`);
      if (response.status === 200) {
        message.success("Clinic deleted successfully");
        getClientDetails();
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await getClientDetails();
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <Card>
      <div className="flex justify-between items-center">
        <h1 className="font-bold text-lg">American EMR Clients</h1>
        <div className="flex">
        <Button type="primary" className="mr-2" onClick={udpateStatus}>Mark All as Unpaid</Button>
        <Button>
          <CSVLink data={transformedClientList(clients)} filename={"Clients_List.csv"} className="csv-link">
            Download CSV
          </CSVLink>
        </Button>
        </div>
      </div>
      <Table
        columns={coloumn}
        dataSource={clients}
        loading={loading}
        size="large"
        className="mt-4"
        scroll={{ x: "max-content" }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          total: clients?.length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
        }}
      />
      <ViewClientRecord
        open={viewRecord?.show}
        onClose={closeViewRecord}
        record={viewRecord?.record?.id}
        title={
          (viewRecord?.record?.office_settings_Basic__facility_name &&
            viewRecord?.record?.office_settings_Basic__facility_name[0]) ||
          "Clinic Info" 
        }
      />
      <ChangeStatus
        open={showEditable}
        onClose={closeEditModal}
        record={editableRecord}
      />

      <ClinicPaymentMethod
        open={showPaymenentModal?.show}
        onClose={closePaymentModal}
        record={showPaymenentModal?.payment_method_record}
        title={
          (showPaymenentModal?.payment_method_record
            ?.office_settings_Basic__facility_name &&
            showPaymenentModal?.payment_method_record
              ?.office_settings_Basic__facility_name[0]) ||
          "Clinic Payment Method"
        }
      />
    </Card>
  );
};

export default Clients;
