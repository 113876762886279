import React from 'react'
import { Route, Routes } from "react-router-dom"
import Login from './pages/Login/Login'
import Clients from './pages/Clients/Clients'
import Dashboard from './pages/Dashboard/Dashboard'
import Layout from './components/Layout/Layout'
import Protected from './components/Protected'
import './App.css'
import VerifyOTP from './pages/VerifyOTP/VerifyOTP'
import Managers from './pages/Managers/Managers'
import SuperAdminNotes from './pages/SuperAdminNote/SuperAdminNotes'
import Profile from './pages/Profile/Profile'
import RegisterClinic from './pages/RegisterClinic/RegisterClinic'
import AuditLog from './pages/AuditLog/AuditLog'

const App = () => {
  return (
    <Routes>
    <Route path='' element={<Login/>}/>
    <Route path='/super-admin-otp-verify/:id' element={<VerifyOTP/>}/>
    <Route path='main' element={<Protected Component={Layout} />}>
       <Route index element={<Dashboard/>}/>
       <Route path='profile' element={<Profile/>}/>
       <Route path='clients' element={<Clients/>}/>
       <Route path='managers' element={<Managers/>}/>
       <Route path='admin-settings' element={<SuperAdminNotes/>}/>
       <Route path='register-clinic' element={<RegisterClinic/>}/>
       <Route path="super-audit-log" element={<AuditLog/>}/>
    </Route>
 </Routes>
  )
}

export default App