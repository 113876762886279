import React from 'react'

const DateComponent = () => {
    const currentDate = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = currentDate && currentDate?.toLocaleString('en-US', options);
    return (
        <>
          <span className="font-bold text-xl" >{formattedDate}</span>
        </>
      );
}

export default DateComponent