import React from 'react'
import {Row, Col} from 'antd'
import DashboardCards from './components/DashboardCards'
import RecentlyAddedUsers from './components/RecentlyAddedUsers'
import DateComponent from '../../components/DateComponent/DateComponent'
// import TotalEarnings from './components/TotalEarnings'

const Dashboard = () => {
  return (
    <div>
      <h1 className="text-[1.125rem] font-semibold text-gray-500">
        Today is <DateComponent/>
      </h1>
      <DashboardCards/>

      <Row gutter={{ xs: 10, md: 15, lg: 24, xl: 24 }} className='mt-4'>
        <Col xs={24} md={24} lg={24}>
          <RecentlyAddedUsers/>
        </Col>
      </Row>

    </div>
  )
}

export default Dashboard