import React, { useEffect, useState } from "react";
import { Modal, Card, message} from "antd";
import { doGet } from "../../../components/API/apis";

// function mbToGb(mbs) {
//   return mbs / 1024;
// }

const ViewClientRecord = ({ open, onClose, record, title }) => {
  const [loading, setLoading] = useState(true);
  const [dataUsageLoading, setDataUsageLoading] = useState(true)
  const [data, setData] = useState(null);
  const [dataUsage, setDataUsage] = useState([])
  const [cloudData, setCloudData] = useState([])
  const [cloudDataLoading, setCloudDataLoading] = useState(true)

  const getClinicUserCount = async (record) => {
    setLoading(true);
    try {
      const response = await doGet(
        `/administration/user/count/?clinic_id=${record}`
      );
      if (response.status === 200) {
        setData(response?.data);
      }
    } catch (error) {
      message.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const getClinicDataUsage = async (record)=>{
    setDataUsageLoading(true)
    try{
      const response = await doGet(`/administration/data/usage/?clinic=${record}`)
      if(response.status === 200){
        setDataUsage(response.data)
      }
      
    }
    catch(error){
        message.error(error)
    }finally{
     setDataUsageLoading(false)
    }
  }

  const getClinicCloudDataUsed = async (record)=>{
    setCloudDataLoading(true)
    try{
      const response = await doGet(`/upload/retrieve-size/${record}/`)
      if(response.status === 200){
        setCloudData(response.data)
      }
      
    }
    catch(error){
        message.error(error)
    }finally{
     setCloudDataLoading(false)
    }
  }

  useEffect(() => {
    open && getClinicUserCount(record);
    open && getClinicDataUsage(record);
    open && getClinicCloudDataUsed(record)
  }, [record]);

  return (
    <Modal
      title={`Clinic Stats - ${title}` }
      open={open}
      onCancel={onClose}
      footer={null}
    >
        <div className="p-2">
          <Card className="mb-2 shadow-sm" hoverable loading={loading}>
            <p className="font-semibold text-center">Total Users <span className="text-gray-400">(Including Historical) </span></p>
            <p className="text-center text-[18px] font-bold">
              {(data && data?.total_staff_count["Total staff count"] + 1) || 0}
            </p>
          </Card>
          <Card className="mb-2 shadow-sm" hoverable loading={loading}>
            <p className="font-semibold text-center">Total Active Users </p>
            <p className="text-center text-[18px] font-bold">
              {(data && data?.total_staff_count["Active staff count"] + 1) || 0}
            </p>
          </Card>
          <Card className="mb-2 flex flex-col" hoverable loading={loading}>
            <p className="font-semibold text-center">
              Total Registered Patient
            </p>
            <p className="text-center text-[18px] font-bold">
              {(data && data?.total_staff_count?.Total_patient_clinic) || 0}
            </p>
          </Card>

          <Card className="flex flex-col mb-2" hoverable loading={dataUsageLoading}>
            <p className="font-semibold text-center">Application Usage Data in MBs </p>
            <p className="text-center text-[18px] font-bold">
              {(dataUsage && dataUsage?.total_data_usage_in_MegaBytes) || 0}
            </p>
          </Card>

          <Card className="flex flex-col mb-2" hoverable loading={dataUsageLoading}>
            <p className="font-semibold text-center">Total Cloud Storage Used in MBs </p>
            <p className="text-center text-[18px] font-bold">
              {(cloudData && cloudData?.total_size_mb) || 0}
            </p>
          </Card>

          <Card className="flex flex-col" hoverable loading={loading}>
            <p className="font-semibold text-center">Payment Due </p>
            <p className="text-center text-[18px] font-bold">
              ${(data && data?.total_staff_count?.payment_due) || 0}
            </p>
          </Card>


        </div>
    </Modal>
  );
};

export default ViewClientRecord;
