import React from 'react'
import AddBankDetails from './component/AddBankDetails'
import LogoUpdate from './component/LogoUpdate'
import ChangeAuthPageBanner from './component/ChangeAuthPageBanner'

const SuperAdminNotes = () => {
  return (
    <>
    <AddBankDetails/>
    <LogoUpdate/>
    <ChangeAuthPageBanner/>
    </>
  )
}

export default SuperAdminNotes