import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import logo from '../../assets/logo.png'
import { useNavigate  } from 'react-router-dom';
import {doPost} from '../../components/API/apis'
import { setSession, getSession } from "../../customHooks";
import { useStateGlobalContext } from "../../Context/GlobalContext";

const Login = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false)
  const {appLogoData, getAppLogo} = useStateGlobalContext()

  useEffect(()=>{
    getAppLogo()
  },[])

  const handleLogin = async (values)=>{
    setLoading(true)
    try{
      const response = await doPost(`/administration/superadmin-login/`, values)
      if(response.status === 200){
        message.success("Please Check your Email to Verify")
        navigate(`/super-admin-otp-verify/${response.data.user_id}`)
        // const { access, refresh, role } = response.data;
        // setSession({ admintoken: access, refreshToken: refresh, userRole: role });
        setLoading(false)
        // navigate('/main')
      }
      else{
        setLoading(false)
        message.error("UnAuthorized User")
        form.resetFields()
      }
    }
    catch(error){
      setLoading(false)
    }
  }

  useEffect(()=>{
    if(getSession('admintoken')){
      navigate('/main')
    }
  },[])

  return !getSession('admintoken') && (
    <div className="flex justify-center items-center h-screen bg-[#0078D2]">
      <div className="flex justify-center align-middle bg-white p-10 rounded-lg max-w-[380px]">
        <Form layout="vertical" form={form} onFinish={handleLogin}>
          <div className="flex justify-center py-4">
          <img  src={appLogoData?.icon || logo} alt="EMR Logo" style={{ width: '80%' }}/>
          </div>
          {/* <h1 className="text-3xl mb-2 font-semibold text-center mt-4">Login</h1> */}
          <Form.Item label='Username' name='username'>
            <Input placeholder="Enter Username" size="large"/>
          </Form.Item>
          <Form.Item label='Password' name='password'>
            <Input.Password placeholder="Enter Password" size="large"/>
          </Form.Item>

          <Button type="primary" htmlType="submit" className="w-full" size="large"
            loading={loading}
          >{loading ? "Loading..." : "Login"} </Button>
        </Form>


        <div>
          
        </div>
      </div>
    </div>
  );
};

export default Login;
