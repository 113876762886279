import { Button, Form, Input, Modal, message, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { doPatch, doPost } from "../../components/API/apis";
import { validatePassword } from "../../utils/utils";
import { useStateGlobalContext } from "../../Context/GlobalContext";

const Profile = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState({});
  const [selectedOption, setSelectedOption] = useState("email");
  const { superAdminDetail, getSuperAdminDetails } = useStateGlobalContext();

  const closeModal = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    form.resetFields();
  };

  const changeCredentials = async () => {
    try {
      const response = await doPost(
        "/administration/update-superadmin/request-otp/"
      );
      if (response.status === 200) {
        message.success(
          "Request Initiated Successfully. Please Check Your Email to Verify OTP"
        );
        setVisible({
          show: true,
          user_id: response.data.user,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeEmailorPassword = async (values) => {
    let data = {
      user_id: visible?.user_id,
      otp: +values.otp,
    };
    if (selectedOption === "email") {
      data.email = values.email;
    } else if (selectedOption === "password") {
      data.new_password = values.password;
    }
    try {
      const response = await doPost(
        "/administration/update-superadmin/",
        data
      );
      if (response.status === 200) {
        selectedOption === "email"
          ? message.success("Email Updated Successfully")
          : message.success("Password Updated Successfully");
        getSuperAdminDetails();
        closeModal();
      } else if(response.status === 400){
        message.error("Invalid OTP")
      }
    } catch (error) {
      message.error("Something Went Wrong");
    }
  };

  useEffect(() => {
    getSuperAdminDetails();
  }, []);

  return (
    <div className="max-w-lg p-6">
      <h1 className="font-semibold text-xl mb-4">Profile Setting</h1>
      <div className="mb-4">
        <h4>Email</h4>
        <p className="text-gray-700">
          {superAdminDetail && superAdminDetail[0]?.email}
        </p>
      </div>
      <Button type="primary" onClick={changeCredentials} className="mb-4">
        Change Email or Password
      </Button>

      <Modal
        title="Change Email or Password"
        open={visible.show}
        onCancel={closeModal}
        footer={null}
      >
        <Form layout="vertical" form={form} onFinish={changeEmailorPassword}>
          <Form.Item label="Select Option" name="option">
            <Radio.Group
              defaultValue={selectedOption}
              onChange={handleOptionChange}
              value={selectedOption}
              buttonStyle="solid"
            >
              <Radio.Button value="email">Change Email</Radio.Button>
              <Radio.Button value="password">Change Password</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Enter OTP"
            name="otp"
            rules={[{ required: true, message: "Please Enter OTP" }]}
          >
            <Input placeholder="OTP" />
          </Form.Item>
          {selectedOption === "email" && (
            <>
            <Form.Item
              label="New Email"
              name="email"
              rules={[{ required: true, message: "Please Enter Email" }]}
            >
              <Input placeholder="Email" type="email" />
            </Form.Item>
            <Form.Item
                label="Verify New Email"
                name="verifyEmail"
                hasFeedback={true}
                dependencies={["email"]}
                rules={[
                  { required: true, message: "Please Enter Your Email!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const email = getFieldValue("email");
                      if (email === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Emails do not match!");
                    },
                  }),
                ]}
              >
                <Input placeholder="Verify Email" type="email" />
              </Form.Item>
              </>
          )}

          {selectedOption === "password" && (
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                { required: true, message: "Please Enter Your password!" },
                { validator: validatePassword },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
          )}

          {selectedOption === "password" && (
            <Form.Item
              label="Confirm New Password"
              name="confirm_password"
              hasFeedback
              dependencies={["password"]}
              rules={[
                { required: true, message: "Please confirm your password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Passwords do not match!");
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm New Password" />
            </Form.Item>
          )}
          <div className="flex justify-end">
            <Button onClick={closeModal} className="mr-2">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Profile;
