import { Col, Modal, Row, Form, Input, Button, message } from "antd";
import React from "react";
import {
  validateName,
  validatePassword,
  validateUsername,
} from "../../utils/utils";
import { doPost } from "../API/apis";
import { useStateGlobalContext } from "../../Context/GlobalContext";

const RegisterClinicModal = ({ open, onClose }) => {
  const [form] = Form.useForm();
  const { getClientDetails} = useStateGlobalContext()

  const onCancel = () => {
    onClose();
    setTimeout(() => form.resetFields(), 300);  
  };


  const onRegister = async (values)=>{
    const data = {
        ...values,
        role: "clinic"
    }
    try{
        const response = await doPost('/user/register/clinic/', data)
        if(response.status === 201){
            message.success("Clinic Registered Successfully. Please Ask the Owner to Verify the OTP")
            onCancel()
            getClientDetails()
        } else if(response.status === 400){
            message.error("Username or Email Already Exists")
        } else{
            message.error("Something Went Wrong")
        }
    } catch(error){
        message.error("Something Went Wrong")
    } 
  }
  return (
    <Modal
      title="Register New Clinic"
      open={open}
      onCancel={onCancel}
      footer={null}
      width={550}
    >
      <Form form={form} layout="vertical" onFinish={onRegister}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
                { validator: validateName },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please input your last name!",
                },
                { validator: validateName },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="username"
          label="Username"
          rules={[
            { required: true, message: "Please enter the username" },
            { validator: validateUsername },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please enter the email" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please enter the password" },
                { validator: validatePassword },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              hasFeedback
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm the password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex justify-end">
          <Button className="mr-2" onClick={onCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit">Register</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default RegisterClinicModal;
