import React, { useState, useEffect } from "react";
import { Table, Button, Modal , message} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import AddManagers from "./component/AddManagers";
import { useStateGlobalContext } from "../../Context/GlobalContext";
import { doDelete } from "../../components/API/apis";



const Managers = () => {
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const {managers, getManagers } = useStateGlobalContext()

    const column = [
        {
          key: 1,
          title: "Username",
          dataIndex: "username",
          width: "20%",
        },
        {
          key: 2,
          title: "Email",
          dataIndex: "email",
          width: "30%",
        },
        {
          key: 3,
          title: "First Name",
          dataIndex: "first_name",
        },
        {
          key: 4,
          title: "Last Name",
          dataIndex: "last_name",
        },
        {
          key: 5,
          title: "Action",
          dataIndex: "action",
          render: (_, record) => (
            <DeleteOutlined
                onClick={() => handleDelete(record?.id)}
              className="bg-[#fff] rounded-md p-1 text-[#e74c3c] "
            />
          ),
        },
      ];
    

      const handleDelete = (record) => {
        console.log(record)
        Modal.confirm({
          title: "Confirm Delete?",
          icon: null,
          content: "Are you sure you want to delete this?",
          okText: "Delete",
          okType: "danger",
          cancelText: "Cancel",
          className: "custom-modal",
          onOk: () => {
            deleteManager(record);
          },
        });
      };

      const deleteManager = async (user_id) => {
        try {
          const response = await doDelete(`/administration/super-admin-manager-delete/${user_id}/`);
          if (response.status === 200) {
            message.success("Clinic deleted successfully");
            getManagers()
          }
        } catch (error) {
          message.error("Something went wrong");
        }
      };


    const openManagerModal = ()=>{
        setVisible(true)
    }

    const onClose = ()=>{
        setVisible(false)
    }

    useEffect(() => {
        async function fetchData() {
          setLoading(true);
          await getManagers();
          setLoading(false);
        }
        fetchData();
      }, []);
   
  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <p className="font-bold text-md" >Managers</p>
        <Button type="primary" onClick={openManagerModal}>Add Managers</Button>
      </div>
      <Table size="small" loading={loading} columns={column} dataSource={managers} pagination={false}/>
      <AddManagers open={visible} onClose={onClose}/>
    </div>
  );
};

export default Managers;
